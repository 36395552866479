import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import { RegisterContext } from '../contexts/RegisterContext';
import { Button } from './Button';
import { FormGroup } from './FormGroup';

type LoginFormProps = {
	onLogin?: (username: string, password: string) => void;
};

const ButtonsFormGroup = styled(FormGroup)`
	button {
		margin-bottom: 20px;
	}
`;

export const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
	const { t } = useTranslation();
	const { login } = useContext(UserContext);
	const { username, setUsername, password, setPassword, errorMessage, setErrorMessage, reset } = useContext(RegisterContext);

	const router = useRouter();

	const [error, setError] = useState(!!errorMessage);

	useEffect(() => {
		let timeout;

		if (error) {
			timeout = setTimeout(() => {
				setErrorMessage(null);
				setError(false);
			}, 5000);
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [error, setErrorMessage]);

	const performLogin = useCallback(
		async (e) => {
			e.preventDefault();

			if (!username || !password) return;

			try {
				await login({
					username,
					password,
				});

				reset();

				if (onLogin) {
					onLogin(username, password);
				}
			} catch (exception) {
				setError(true);
			}
		},
		[login, onLogin, password, reset, username]
	);

	const onUsernameChange = useCallback((e) => setUsername(e.target.value.trim()), [setUsername]);
	const onPasswordChange = useCallback((e) => setPassword(e.target.value.trim()), [setPassword]);

	const onRegistrationClick = useCallback(
		(e) => {
			e.preventDefault();
			router.push('/register');
		},
		[router]
	);

	return (
		<form className={'login-form form-large'} onSubmit={performLogin}>
			{error && <div className={'alert alert-danger'}>{errorMessage || t('Login.Error.LoginFailed')}</div>}
			<div className={'form-group'}>
				<label htmlFor={'login-username'}>{t('Login.Email')}</label>
				<input
					id={'login-username'}
					autoFocus={!username}
					type={'email'}
					autoCapitalize={'false'}
					autoCorrect={'false'}
					autoComplete={'username'}
					className={'form-control'}
					value={username || ''}
					onChange={onUsernameChange}
				/>
			</div>
			<div className={'form-group'}>
				<label htmlFor={'login-password'}>{t('Login.Password')}</label>
				<input id={'login-password'} autoFocus={!!username} autoComplete={'current-password'} type={'password'} className={'form-control'} value={password || ''} onChange={onPasswordChange} />
			</div>
			<ButtonsFormGroup stacked>
				<Button primary type={'submit'} title={String(t('Login.Submit'))} />
				<Button type={'button'} title={t('Login.Register')} onClick={onRegistrationClick} />
			</ButtonsFormGroup>
			<div className={'form-actions'}>
				<Link href={'/forgot'}>{t('Login.ForgotPassword')}</Link>
			</div>
		</form>
	);
};
