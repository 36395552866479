import styled from 'styled-components';

type FormGroupProps = {
	center?: boolean;
	stacked?: boolean;
};

export const FormGroup = styled.div<FormGroupProps>`
	margin-bottom: 20px;
	display: ${(props) => (props.center || props.stacked ? 'flex' : 'block')};
	flex-direction: ${(props) => (props.stacked ? 'column' : 'row')};
	justify-content: ${(props) => (props.center ? 'center' : 'left')};
`;
