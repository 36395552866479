import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { breakpoints } from '../styles/breakpoints';
import { BUTTON_BACKGROUND, BUTTON_BACKGROUND_ACTIVE, PRIMARY, PRIMARY_ACTIVE, SECONDARY, SECONDARY_DARKENED } from '../styles/colors';

type ButtonContainerProps = {
	withSubtitle: boolean;
	primary?: boolean;
};

export const ButtonBaseContainer = styled(BaseButton)<ButtonContainerProps>`
	position: relative;
	height: 72px;
	padding: 10px 20px;
	background-color: ${(props) => (props.primary ? PRIMARY : BUTTON_BACKGROUND)};
	color: ${SECONDARY_DARKENED};
	border: 1px solid transparent;
	border-radius: 6px;
	transition: background 0.2s ease-in;
	cursor: pointer;
	font-size: 22px;

	&:focus {
		outline: none;
		background-color: ${(props) => (props.primary ? PRIMARY_ACTIVE : BUTTON_BACKGROUND_ACTIVE)};
	}

	@media (hover) {
		&:hover {
			background-color: ${(props) => (props.primary ? PRIMARY_ACTIVE : BUTTON_BACKGROUND_ACTIVE)};
		}
	}
`;

export const ButtonContainer = styled(ButtonBaseContainer)<ButtonContainerProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	align-items: ${(props) => (props.withSubtitle ? 'left' : 'center')};

	@media ${breakpoints.sm} {
		flex: auto;
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ButtonContainerLarge = styled(ButtonContainer)`
	width: 100%;
`;

export const ButtonTitle = styled.span<ButtonContainerProps>`
	font-size: 18px;
	//text-transform: uppercase;
	line-height: 1.2;
	font-weight: bold;
	text-align: ${(props) => (props.withSubtitle ? 'left' : 'center')};

	@media ${breakpoints.sm} {
		font-size: 20px;
	}

	@media ${breakpoints.md} {
		font-size: 22px;
	}
`;

export const ButtonSubtitle = styled(ButtonTitle)`
	color: ${SECONDARY};
	font-size: 16px;

	@media ${breakpoints.sm} {
		font-size: 18px;
	}

	@media ${breakpoints.md} {
		font-size: 20px;
	}
`;

export type ButtonProps = {
	title?: string;
	subtitle?: string;
	type?: 'submit' | 'reset' | 'button';
	onClick?: (event: React.SyntheticEvent) => void;
	primary?: boolean;
};

export const Button: React.FC<ButtonProps> = ({ title, subtitle, type, onClick, primary }) => {
	return (
		<ButtonContainer type={type} onClick={onClick} withSubtitle={!!subtitle} primary={primary}>
			<ButtonTitle withSubtitle={!!subtitle}>{title}</ButtonTitle>
			<ButtonSubtitle withSubtitle={!!subtitle}>{subtitle}</ButtonSubtitle>
		</ButtonContainer>
	);
};
