import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useEffectOnce } from 'react-use';
import { useApolloClient } from '@apollo/client';
import { LoginForm } from '../components/LoginForm';
import SecurityUtility from '../utils/SecurityUtility';
import { UserContext } from '../contexts/UserContext';
import MainTitle from '../components/MainTitle';
import { PRIMARY } from '../styles/colors';
import { LoginBlocked, LoginBlockedReason } from '../components/LoginBlocked';
import { HeaderGap } from '../components/HeaderGap/HeaderGap.styles';
import { useHasMounted } from '../hooks/useHasMounted';

const LoginContentContainer = styled.div`
	width: 100%;
	max-width: 520px;
	margin: 0 auto;

	.alert {
		text-align: center;
	}
`;

export default function LoginScene() {
	const { t } = useTranslation();
	const { isLoggedIn } = useContext(UserContext);
	const apolloClient = useApolloClient();
	const router = useRouter();
	const hasMounted = useHasMounted();

	useEffectOnce(() => {
		(async () => {
			try {
				await apolloClient.clearStore();
			} catch (error) {} // Ignore error
		})();
	});

	const redirect = useCallback(() => {
		let redirectUrl = '/profiles';

		if (router.query.r) {
			if (Array.isArray(router.query.r)) {
				// eslint-disable-next-line prefer-destructuring
				redirectUrl = router.query.r[0];
			} else {
				redirectUrl = router.query.r;
			}
		}

		router.replace(redirectUrl);
	}, [router]);

	useEffect(() => {
		if (isLoggedIn) {
			redirect();
		}
	}, [isLoggedIn, redirect]);

	const loginForm = useMemo(() => {
		if (SecurityUtility.isLocalStorageAllowed()) {
			return <LoginForm />;
		}

		return <LoginBlocked reason={LoginBlockedReason.NO_LOCAL_STORAGE} />;
	}, []);

	const content = useMemo(() => {
		if (isLoggedIn) return null;

		return (
			<HeaderGap>
				<div className={'login-scene'}>
					<MainTitle title={t('Login.Title')} color={PRIMARY} />
					<LoginContentContainer>{loginForm}</LoginContentContainer>
				</div>
			</HeaderGap>
		);
	}, [isLoggedIn, loginForm, t]);

	if (!hasMounted) {
		return null;
	}

	return <>{content}</>;
}
